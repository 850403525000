import http from './httpInstance';

export const get = async (path, headers, onDownloadProgress) => {
  const response = await http({
    method: 'get',
    url: path,
    headers: {
      'Content-Type': 'application/json',
      ...authzHeader(),
      ...headers,
    },
    onDownloadProgress
  });
  return response.data;
};

export const post = async ({ path, headers, data }) => {
  const response = await http({
    method: 'post',
    url: path,
    data,
    headers: {
      'Content-Type': 'application/json',
      ...authzHeader(),
      ...headers,
    },
  });
  return response;
};



export const remove = async ({ path, headers, data }) => {
  const response = await http({
    method: 'delete',
    url: path,
    data,
    headers: {
      'Content-Type': 'application/json',
      ...authzHeader(),
      ...headers,
    },
  });
  return response;
};



export const upload = async ({ path, headers, data }) => {
  const response = await http({
    method: 'post',
    url: path,
    data,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...authzHeader(),
      ...headers,
    },
  });
  return response;
};

function authzHeader() {
  return { Authorization: localStorage.getItem('accessToken') };
}
