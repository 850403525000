import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// Cognito
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { UserPool } from '../config';
// utils
import { get, post } from '../utils/server';
import { setSession, getToken, validateToken } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');
        const cognitoUser = await validateToken(accessToken);
        if (cognitoUser) {
          setSession(accessToken);
          const dbUser = await get(`mlros/${cognitoUser.sub}`);

          const user = { ...dbUser.data, ...cognitoUser };

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const cognitoUser = await cognitoLogin(email, password);

    const token = getToken(cognitoUser);
    setSession(token);

    const dbUser = await get(`mlros/${cognitoUser.user.sub}`);
    const user = { ...cognitoUser.user, ...dbUser.data };

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  const cognitoLogin = (email, password) =>
    new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: UserPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          const token = data.idToken.jwtToken;
          const user = data.idToken.payload;
          resolve({ token, user });
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (data) => {
          reject(new Error({ error: 'newPasswordRequired', data }));
        },
      });
    });

  const register = async (email, password, firstName, lastName) => {
    const response = await post({
      url: '/api/account/register',
      data: {
        email,
        password,
        firstName,
        lastName,
      },
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
