import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts

import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'analytics', element: <GeneralAnalytics /> },
        { path: 'banking', element: <GeneralBanking /> },
        // {
        //   path: 'user',
        //   children: [
        //     { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
        //     { path: 'profile', element: <UserProfile /> },
        //     { path: 'cards', element: <UserCards /> },
        //     { path: 'list', element: <UserList /> },
        //     { path: 'new', element: <UserCreate /> },
        //     { path: ':name/edit', element: <UserCreate /> },
        //     { path: ':id/review', element: <UserAccount /> },
        //     { path: 'account', element: <UserAccount /> },
        //   ],
        // },

        {
          path: 'customer',
          children: [
            { element: <Navigate to="/dashboard/customer/list" replace />, index: true },
            { path: 'list', element: <CustomerList /> },
            { path: ':id/review', element: <CustomerReview /> },
          ],
        },

        {
          path: 'business',
          children: [
            { element: <Navigate to="/dashboard/business/list" replace />, index: true },
            { path: 'list', element: <BusinessList /> },
            { path: ':id/review', element: <BusinessReview /> },
          ],
        },

        {
          path: 'payees',
          children: [
            { element: <Navigate to="/dashboard/payees/list" replace />, index: true },
            { path: 'list', element: <PayeesList /> },
            { path: ':id/review/:businessId', element: <PayeesReview /> },
          ],
        },

        {
          path: 'accounts',
          children: [
            { element: <Navigate to="/dashboard/accounts/list" replace />, index: true },
            { path: 'list', element: <AccountsList /> },
            { path: ':id/review/:businessId', element: <AccountsReview /> },
          ],
        },

        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },

        {
          path: 'compliance',
          children: [
            { element: <TMPatterns />, index: true },
          ],
        },

        {
          path: 'files',
          children: [
            { path: '/files', element: <FileManager /> },
            { path: ':folder', element: <FileManager /> },
          ],
        },
        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    // {
    //   path: '/',
    //   element: <MainLayout />,
    //   children: [
    //     { element: <HomePage />, index: true },
    //     { path: 'about-us', element: <About /> },
    //     { path: 'contact-us', element: <Contact /> },
    //     { path: 'faqs', element: <Faqs /> },
    //   ],
    // },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));

// Customers
const CustomerReview = Loadable(lazy(() => import('../pages/dashboard/customer/CustomerReview')));
const CustomerList = Loadable(lazy(() => import('../pages/dashboard/customer/CustomerList')));

// User
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
// const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

/// Business
const BusinessReview = Loadable(lazy(() => import('../pages/dashboard/business/BusinessReview')));
const BusinessList = Loadable(lazy(() => import('../pages/dashboard/business/BusinessList')));

// Payees
const PayeesReview = Loadable(lazy(() => import('../pages/dashboard/payees/PayeesReview')));
const PayeesList = Loadable(lazy(() => import('../pages/dashboard/payees/PayeesList')));

// Accounts
const AccountsReview = Loadable(lazy(() => import('../pages/dashboard/accounts/AccountsReview')));
const AccountsList = Loadable(lazy(() => import('../pages/dashboard/accounts/AccountsList')));

// compliance
const TMPatterns = Loadable(lazy(() => import('../pages/dashboard/compliance/TMPatterns')));

// files
const FileManager = Loadable(lazy(() => import('../pages/dashboard/files/FileManager')));


// APP
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN

const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
