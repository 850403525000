import jwtDecode from 'jwt-decode';
// Config
import API from './apiConfig';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import http from './httpInstance';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = decodeAccessToken(accessToken);

  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
  let timeLeft = 0;
  let expiredTimer;
  if (exp) {
    const currentTime = Date.now();

    timeLeft = exp * 1000 - currentTime;
  }

  // console.log({ currentTime, exp });

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // eslint-disable-next-line no-alert
    alert('Session expired');

    localStorage.removeItem('accessToken');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    // http.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    http.defaults.headers.common.Authorization = accessToken;

    const { exp } = decodeAccessToken(accessToken);

    // This function below will handle when token is expired
    // const { exp } = jwtDecode(accessToken); // ~5 days by minimals server
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    // delete http.defaults.headers.common.Authorization;
    delete http.defaults.headers.common.Authorization;
  }
};

function decodeAccessToken(accessToken) {
  if (['local', 'dev'].includes(process.env.REACT_APP_ENV)) return JSON.parse(accessToken);
  if (['stg', 'prod'].includes(process.env.REACT_APP_ENV)) return jwtDecode(accessToken);
  return { exp: null };
}

function getToken(cognitoUser) {
  if (['local', 'dev'].includes(process.env.REACT_APP_ENV))
    return JSON.stringify({
      role: cognitoUser.user['custom:role'],
      user_id: cognitoUser.user.sub,
      exp: Date.now() + 3600000, // + 1 hour
      ...cognitoUser.user,
    });
  if (['stg', 'prod'].includes(process.env.REACT_APP_ENV)) return cognitoUser.token;
}

async function validateToken(accessToken) {
  if (accessToken && isValidToken(accessToken)) {
    if (['local', 'dev'].includes(process.env.REACT_APP_ENV)) return JSON.parse(accessToken);
    if (['stg', 'prod'].includes(process.env.REACT_APP_ENV)) return (await http.get(API.VERIFY_MLRO_AUTH)).data;
  }
}

export { isValidToken, setSession, decodeAccessToken, getToken, validateToken };
