// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, 'app'),
    ecommerce: path(ROOTS_DASHBOARD, 'ecommerce'),
    analytics: path(ROOTS_DASHBOARD, 'analytics'),
    banking: path(ROOTS_DASHBOARD, 'banking'),
    booking: path(ROOTS_DASHBOARD, 'booking'),
  },

  chat: {
    root: path(ROOTS_DASHBOARD, 'chat'),
    new: path(ROOTS_DASHBOARD, 'chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `chat/${name}`),
  },
  customer: {
    root: path(ROOTS_DASHBOARD, 'customer'),
    review: (id) => path(ROOTS_DASHBOARD, `customer/${id}/review`),
    list: path(ROOTS_DASHBOARD, 'customer/list'),
  },
  business: {
    root: path(ROOTS_DASHBOARD, 'business'),
    review: (id) => path(ROOTS_DASHBOARD, `business/${id}/review`),
    list: path(ROOTS_DASHBOARD, 'business/list'),
  },
  payees: {
    root: path(ROOTS_DASHBOARD, 'payees'),
    review: (id, businessId) => path(ROOTS_DASHBOARD, `payees/${id}/review/${businessId}`),
    list: path(ROOTS_DASHBOARD, 'payees/list'),
  },
  accounts: {
    root: path(ROOTS_DASHBOARD, 'accounts'),
    review: (id, businessId) => path(ROOTS_DASHBOARD, `accounts/${id}/review/${businessId}`),
    list: path(ROOTS_DASHBOARD, 'accounts/list'),
  },
  compliance: {
    root: path(ROOTS_DASHBOARD, 'compliance'),
  },
  files: {
    root: path(ROOTS_DASHBOARD, 'files'),
    folder: (folder) => path(ROOTS_DASHBOARD, `files/${folder}`),
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
