import { createSlice } from '@reduxjs/toolkit';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
// utils
import API from '../../utils/apiConfig';
import { get, post } from '../../utils/server';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const actionsNames = {
  approve: 'approve',
  decline: 'decline',
  close: 'close',
  open: 'open',
  hide: 'hide',
};

const initialState = {
  isLoading: false,
  error: null,
  businesses: [],
  business: null,
  sortBy: null,
  actionsOn: [],
  alert: {
    show: false,
    title: 'Alert',
    messsage: '',
    type: 'error',
  },
};

const slice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Businesses
    getBusinessesSuccess(state, action) {
      state.isLoading = false;
      state.businesses = action.payload;
    },

    getCustomerBusinessesSuccess(state, action) {
      state.isLoading = false;
      state.businesses = action.payload;
    },

    // GET Single Business
    getBusinessSuccess(state, action) {
      state.isLoading = false;
      state.business = action.payload;
    },

    // Actions //
    /// Actions for Customer
    approveBusinessSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Business Successfully Approved', type: 'success' };
      state.business = { ...state.business, mlroStatus: 'APPROVED' };
    },

    declineBusinessSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Business Successfully Declined', type: 'success' };
      state.business = { ...state.business, mlroStatus: 'DECLINED' };
    },

    hideBusinessSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Business Successfully HIDDEN', type: 'success' };
      state.business = { ...state.business, status: 'HIDDEN' };
    },
    closeBusinessSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Business Successfully Closed', type: 'success' };
      state.business = { ...state.business, status: 'CLOSED' };
    },
    openBusinessSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Business Successfully Opened', type: 'success' };
      state.business = { ...state.business, status: 'OPEN' };
    },
    actionFailed(state, action) {
      state.alert = { show: true, title: 'Failed', messsage: action.payload.errors[0].message, type: 'error' };
    },

    // Actions started
    actionStarted(state, action) {
      state.actionsOn = [...state.actionsOn, action.payload];
    },
    actionEnd(state, action) {
      state.actionsOn = state.actionsOn.filter((actionType) => actionType !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

export function getBusinesses(mlroStatus) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_BUSINESSES({ mlroStatus }));
      dispatch(slice.actions.getBusinessesSuccess(response.businesses));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomerBusinesses(customerId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_BUSINESSES({ customer_id: customerId }));
      dispatch(slice.actions.getCustomerBusinessesSuccess(response.businesses));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBusiness(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_BUSINESS(id));
      dispatch(slice.actions.getBusinessSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------- Actions -------------------- \\
export function approveBusiness(id, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.approve));
    try {
      const response = await post({ path: API.APPROVE_BUSINESS(id) });
      callBack('Business Approved Successfuly', 'success');
      dispatch(slice.actions.approveBusinessSuccess(response.data));
      dispatch(slice.actions.actionEnd(actionsNames.approve));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.approve));
    }
  };
}

export function declineBusiness(id, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.decline));
    try {
      const response = await post({ path: API.DECLINE_BUSINESS(id) });
      callBack('Business Declined Successfuly', 'success');
      dispatch(slice.actions.declineBusinessSuccess(response.data));
      dispatch(slice.actions.actionEnd(actionsNames.decline));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.decline));
    }
  };
}

export function openBusiness(id, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.open));
    try {
      const response = await post({ path: API.OPEN_BUSINESS(id) });
      callBack('Business Re-Opened Successfuly', 'success');
      dispatch(slice.actions.openBusinessSuccess(response.data));
      dispatch(slice.actions.actionEnd(actionsNames.open));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.actionEnd(actionsNames.open));
    }
  };
}

export function closeBusiness(id, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.close));
    try {
      const response = await post({ path: API.CLOSE_BUSINESS(id) });
      callBack('Business Closed Successfuly', 'success');
      dispatch(slice.actions.closeBusinessSuccess(response.data));
      dispatch(slice.actions.actionEnd(actionsNames.close));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.actionEnd(actionsNames.close));
    }
  };
}

export function hideBusiness(id, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.hide));

    try {
      const response = await post({ path: API.HIDE_BUSINESS(id) });
      callBack('Business Hidden Successfuly', 'success');
      dispatch(slice.actions.hideBusinessSuccess(response.data));
      dispatch(slice.actions.actionEnd(actionsNames.hide));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.actionEnd(actionsNames.hide));
    }
  };
}
