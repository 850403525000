import { useContext } from 'react';
//
import { StartedContext } from '../contexts/StartedContext';

// ----------------------------------------------------------------------

const useStarted = () => {
  const context = useContext(StartedContext);

  if (!context) throw new Error('Start context must be use inside AuthProvider');

  return context;
};

export default useStarted;
