import { createSlice } from '@reduxjs/toolkit';
// utils
import API from '../../utils/apiConfig';
import { get } from '../../utils/server';
///
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: null,
  transactions: [],
  pages: 1,
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get Customer Account Transactions
    getTransactionsSuccess(state, action) {
      state.isLoading = false;
      state.transactions = action.payload.transactions;
      state.page = action.payload.number_of_pages;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

//
export function getCustomerTransactions(accountId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = { page_number: 1 };
      const response = await get(API.GET_TRANSACTIONS(accountId, query));
      dispatch(slice.actions.getTransactionsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBusinessTransactions(accountId, businessId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = { page_number: 1 };
      const response = await get(API.GET_TRANSACTIONS(accountId, query));
      dispatch(slice.actions.getTransactionsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
