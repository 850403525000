import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import businessReducer from './slices/business';
import customerReducer from './slices/customer';
import accountReducer from './slices/account';
import payeeReducer from './slices/payee';
import discussionReducer from './slices/discussion';
import fileReducer from './slices/file';
import noteReducer from './slices/note';
import transactionReducer from './slices/transaction';
import chatReducer from './slices/chat';
import complianceReducer from './slices/compliance';
// import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
// import productReducer from './slices/product';
// import calendarReducer from './slices/calendar';
// import kanbanReducer from './slices/kanban';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout'],
// };

const rootReducer = combineReducers({
  business: businessReducer,
  customer: customerReducer,
  account: accountReducer,
  payee: payeeReducer,
  discussion: discussionReducer,
  file: fileReducer,
  note: noteReducer,
  transaction: transactionReducer,
  chat: chatReducer,
  compliance: complianceReducer,
});

export { rootPersistConfig, rootReducer };
