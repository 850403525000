import { createSlice } from '@reduxjs/toolkit';

// utils
import API from '../../utils/apiConfig';
import { get, post } from '../../utils/server';
///
import { dispatch } from '../store';




const initialState = {
  isLoading: {
    getTMPatterns: false,
    getTMPatternsData: false,
    exporting: false,
  },
  patterns: [],
  error: null,
  data: {
    list: [],
    count: 0,
    columns: [],
  },

};

const slice = createSlice({
  name: 'compliance',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state, action) {
      state.isLoading = {
        ...state.isLoading,
        ...action.payload
      };
    },

    stopLoading(state, action) {
      state.isLoading = {
        ...state.isLoading,
        ...action.payload
      };
    }
    ,
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = {
        getTMPatterns: false,
        getTMPatternsData: false,
      }
      state.error = action.payload;
    },

    // GET Accounts
    getTMPatternsSuccess(state, action) {
      state.isLoading = {
        ...state.isLoading,
        getTMPatterns: false,
      }
      state.patterns = action.payload;
    },

    // Get Accounts Releated to Business
    getTMPatternsData(state, action) {
      state.isLoading = {
        ...state.isLoading,
        getTMPatternsData: false,
      }
      state.data = action.payload;
    },


    resetData(state) {
      state.data = [];
    }




  },
});

// Reducer
export default slice.reducer;

export const {
  resetData
} = slice.actions;


// ----------------------------------------------------------------------

export function getTMPatterns() {
  return async () => {
    dispatch(slice.actions.startLoading({
      getTMPatterns: true
    }));
    try {
      const response = await get(API.GET_TM_PATTERNS());
      console.log({ response })
      dispatch(slice.actions.getTMPatternsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTMPatternsData(patternID, query) {
  return async () => {
    dispatch(slice.actions.startLoading({
      getTMPatternsData: true
    }));
    try {

      const endpoint = API.GET_TM_PATTERN_DATA(patternID, query);
      const response = await get(endpoint);
      dispatch(slice.actions.getTMPatternsData(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function ExportComplicance(patternID, data, onSuccess, onFailed) {

  return async () => {
    dispatch(slice.actions.startLoading({
      exporting: true
    }));
    try {
      const endpoint = API.EXPORT_COMPLIANCE(patternID, data.metaData);
      const response = await post({ path: endpoint, data });
      const pathName = response.data.path;
      const folders = pathName.split('/').slice(1, -1).join('/');
      dispatch(slice.actions.stopLoading({
        exporting: false
      }));
      if (typeof onSuccess === 'function') onSuccess(folders);
    } catch (error) {
      if (typeof onFailed === 'function') onFailed(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}