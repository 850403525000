import PropTypes from 'prop-types';
import { createContext, useEffect, useState } from 'react';

// ----------------------------------------------------------------------
const StartedContext = createContext({
  start: false,
});

// ----------------------------------------------------------------------

StartedProvider.propTypes = {
  children: PropTypes.node,
};

function StartedProvider({ children }) {
  const [started, setStarted] = useState(false);

  return <StartedContext.Provider value={[started, setStarted]}>{children}</StartedContext.Provider>;
}

export { StartedContext, StartedProvider };
