import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/httpInstance';
import API from '../../utils/apiConfig';
import { post, get } from '../../utils/server';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingDiscussion: false,
  error: null,
  discussions: [],
  discussion: null,
  numberOfPages: 1,
  messages: [],
};

const slice = createSlice({
  name: 'discussion',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingDiscussion(state) {
      state.isLoadingDiscussion = true;
    },
    cleanDiscussions(state) {
      state.discussion = null;
      state.discussions = [];
      state.messages = [];
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET all Discussions
    getDiscussionsSuccess(state, action) {
      state.messages = [];
      state.discussions = action.payload.discussions;
      state.numberOfPages = action.payload.numberOfPages;
      state.discussion = null;
      state.isLoading = false;
      state.isLoadingDiscussion = false;
    },

    // get discussion related to customer
    getCustomerDiscussionsSuccess(state, action) {
      state.isLoading = false;
      state.messages = [];
      state.discussion = false;
      state.discussions = action.payload.discussions;
      state.numberOfPages = action.payload.numberOfPages;
    },

    // Get one single Discussion
    getDiscussionSuccess(state, action) {
      state.isLoading = false;
      state.discussion = action.payload;
    },

    // Get Messages
    getMessagesSuccess(state, action) {
      state.isLoading = false;
      state.messages = action.payload;
    },

    sendMessagesSuccess(state, action) {
      // state.messages.push(action.payload);
    },
    receiveMessagesSuccess(state, action) {
      state.messages.push(action.payload);
    },
    createDiscussionSuccess(state, action) {
      state.discussions = [action.payload, ...state.discussions];
      state.discussion = action.payload;
    },

    selectDiscussionSuccess(state, action) {
      state.messages = [];
      state.discussion = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getDiscussions(mlroId) {
  return async () => {
    dispatch(slice.actions.cleanDiscussions());
    dispatch(slice.actions.startLoadingDiscussion());
    try {
      const query = {
        type: 'MLRO_ENQUIRY',
        item_per_page: 100,
        page_number: 1,
      };
      const response = await get(API.GET_DISCUSSIONS(query));
      dispatch(slice.actions.getDiscussionsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomerDiscussions(customerId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = {
        type: 'MLRO_ENQUIRY',
        item_per_page: 100,
        page_number: 1,
        customer_id: customerId,
      };
      const response = await get(API.GET_DISCUSSIONS(query));
      dispatch(slice.actions.getCustomerDiscussionsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDiscussion(id, mlroId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_DISCUSSION(id));
      dispatch(slice.actions.getDiscussionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMessages(id, mlroId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = {
        limit: 15,
        pages: 1,
      };
      const response = await get(API.GET_MESSAGES(id, query));
      dispatch(slice.actions.getMessagesSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function sendMessage(id, message, attachment, MlroId) {
  return async () => {
    try {
      const response = await post({
        path: API.CREATE_MESSAGE(id),
        data: {
          sender_id: MlroId,
          content: message,
          attachment,
        },
      });

      dispatch(slice.actions.sendMessagesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function receiveMessage(message) {
  return async () => {
    if (message) {
      dispatch(slice.actions.receiveMessagesSuccess(message));
    }
  };
}

export function createDiscussion(customerId, subject, mlroId) {
  return async () => {
    try {
      const response = await post({
        path: API.CREATE_DISCUSSION,
        data: {
          mlro_id: mlroId,
          customer_id: customerId,
          subject,
        },
      });
      dispatch(slice.actions.createDiscussionSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function selectDiscussion(discussion) {
  return async () => {
    if (discussion) {
      dispatch(slice.actions.selectDiscussionSuccess(discussion));
    }
  };
}
