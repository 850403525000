import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  const width = 40;
  const height = 40;

  // OR
  // const logo = '/logo/logo_single.svg';

  const logo = (
    <Box sx={{ width, height, ...sx }}>
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 512 512">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>

        <g fill={PRIMARY_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1">
          <path
            fill="url(#BG1)"
            d="M183.168 285.573l-2.918 5.298-2.973 5.363-2.846 5.095-2.274 4.043-2.186 3.857-2.506 4.383-1.6 2.774-2.294 3.939-1.099 1.869-1.416 2.388-1.025 1.713-1.317 2.18-.95 1.558-1.514 2.447-.866 1.38-.833 1.312-.802 1.246-.77 1.18-.739 1.111-.935 1.38-.664.956-.425.6-.41.572-.59.8-.376.497-.537.69-.171.214c-10.76 13.37-22.496 23.493-36.93 29.334-30.346 14.262-68.07 14.929-97.202-2.704l72.347-124.682 2.8-1.72c49.257-29.326 73.08 1.117 94.02 40.927z"
          />
          <path
            fill="url(#BG2)"
            d="M444.31 229.726c-46.27-80.956-94.1-157.228-149.043-45.344-7.516 14.384-12.995 42.337-25.267 42.337v-.142c-12.272 0-17.75-27.953-25.265-42.337C189.79 72.356 141.96 148.628 95.69 229.584c-3.483 6.106-6.828 11.932-9.69 16.996 106.038-67.127 97.11 135.667 184 137.278V384c86.891-1.611 77.962-204.405 184-137.28-2.86-5.062-6.206-10.888-9.69-16.994"
          />
          <path
            fill="url(#BG3)"
            d="M450 384c26.509 0 48-21.491 48-48s-21.491-48-48-48-48 21.491-48 48 21.491 48 48 48"
          />
        </g>
      </svg> */}
      <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        {...sx}
        viewBox="0 0 722.000000 722.000000"
        preserveAspectRatio="xMidYMid meet"
      >
        <g transform="translate(0.000000,722.000000) scale(0.100000,-0.100000)" fill={PRIMARY_LIGHT} stroke="none">
          <path
            d="M3360 7214 c-285 -26 -524 -69 -761 -138 -589 -172 -1101 -475 -1535
            -910 -231 -232 -394 -438 -549 -696 -624 -1039 -683 -2335 -156 -3425 182
            -376 403 -687 696 -981 587 -588 1315 -939 2155 -1040 200 -24 600 -24 800 0
            719 86 1360 359 1907 810 223 185 487 471 653 711 812 1167 865 2709 135 3925
            -155 258 -318 464 -549 696 -565 565 -1278 917 -2080 1025 -145 19 -594 34
            -716 23z m625 -689 c604 -81 1147 -332 1598 -739 724 -654 1076 -1641 932
            -2608 -183 -1220 -1112 -2199 -2319 -2444 -376 -77 -807 -76 -1178 0 -566 118
            -1073 392 -1483 801 -834 835 -1089 2077 -650 3171 396 986 1304 1688 2355
            1818 69 9 148 18 175 20 103 8 454 -4 570 -19z"
          />
          <path
            d="M3840 5414 c-23 -10 -381 -361 -1022 -1002 -1083 -1081 -1036 -1030
            -1025 -1138 3 -28 13 -67 22 -85 10 -19 99 -116 199 -216 190 -191 234 -222
            310 -223 102 0 45 -53 1114 1014 600 599 1001 1006 1014 1030 25 48 27 136 4
            190 -21 51 -344 378 -411 417 -40 23 -62 29 -110 29 -33 -1 -76 -8 -95 -16z"
          />
          <path
            d="M4825 4468 c-35 -9 -164 -134 -1039 -1007 -688 -687 -1004 -1008
            -1017 -1036 -10 -22 -19 -62 -19 -91 0 -87 31 -132 233 -331 161 -159 188
            -182 236 -198 65 -21 127 -15 185 19 63 38 1980 1962 2005 2013 13 27 21 61
            21 96 0 91 -29 133 -233 334 -166 164 -188 183 -237 197 -60 18 -77 18 -135 4z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
