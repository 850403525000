import { createSlice } from '@reduxjs/toolkit';
// utils
import API from '../../utils/apiConfig';
import { post, get } from '../../utils/server';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------
const actionsNames = {
  approve: 'approve',
  decline: 'decline',
  open: 'open',
  close: 'close',
  freeze: 'freeze',
};

const initialState = {
  isLoading: false,
  error: null,
  payees: [],
  payee: null,
  sortBy: null,
  numberOfPages: 1,
  actionsOn: [],
  alert: {
    show: false,
    title: 'Alert',
    messsage: '',
    type: 'error',
  },
};

const slice = createSlice({
  name: 'payee',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Accounts
    getPayeesSuccess(state, action) {
      state.isLoading = false;
      state.payees = action.payload.payees;
      state.numberOfPages = action.payload.number_of_pages;
    },

    // Get Accounts Releated to Business
    getBusinessPayeesSuccess(state, action) {
      state.isLoading = false;
      state.payees = action.payload;
    },

    // Get Accounts Releated to Customer
    getCustomerPayeesSuccess(state, action) {
      state.isLoading = false;
      state.payees = action.payload;
    },

    // GET single Account
    getPayeeSuccess(state, action) {
      state.isLoading = false;
      state.payee = action.payload;
    },

    /// Actions for Customer
    approvePayeeSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Payee Successfully Approved', type: 'success' };
      state.payee = { ...state.payee, mlroStatus: 'APPROVED' };
    },

    declinePayeeSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Payee Successfully Declined', type: 'success' };
      state.payee = { ...state.payee, mlroStatus: 'DECLINED' };
    },

    freezePayeeSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Payee Successfully Frozen', type: 'success' };
      state.payee = { ...state.payee, status: 'FROZEN' };
    },
    closePayeeSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Payee Successfully Closed', type: 'success' };
      state.payee = { ...state.payee, status: 'CLOSED' };
    },

    openPayeeSuccess(state, action) {
      state.alert = { show: true, title: 'Success', messsage: 'Payee Successfully Opened', type: 'success' };
      state.payee = { ...state.payee, status: 'OPEN' };
    },
    actionFailed(state, action) {
      state.alert = { show: true, title: 'Failed', messsage: action.payload.errors[0].message, type: 'error' };
    },

    // Actions started
    actionStarted(state, action) {
      state.actionsOn = [...state.actionsOn, action.payload];
    },
    actionEnd(state, action) {
      state.actionsOn = state.actionsOn.filter((actionType) => actionType !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

/// need Endpoint
export function getPayees(query) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_PAYEES(query));
      dispatch(slice.actions.getPayeesSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBusinessPayees(businessId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = { business_id: businessId };
      const response = await get(API.GET_PAYEES(query));
      dispatch(slice.actions.getBusinessPayeesSuccess(response.payees));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCustomerPayees(customerId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = { customer_id: customerId };
      const response = await get(API.GET_PAYEES(query));
      dispatch(slice.actions.getCustomerPayeesSuccess(response.payees));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getCustomerPayee(payeeId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_PAYEE(payeeId));
      dispatch(slice.actions.getPayeeSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBusinessPayee(payeeId, businessId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_PAYEE(payeeId));
      dispatch(slice.actions.getPayeeSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ------------------------  actions

export function approvePayee(payeeId, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.approve));
    try {
      const response = await post({ path: API.APPROVE_PAYEE(payeeId) });
      callBack('Payee Approved Successfully ', 'success');
      dispatch(slice.actions.approvePayeeSuccess(response));
      dispatch(slice.actions.actionEnd(actionsNames.approve));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.approve));
    }
  };
}

export function declinePayee(payeeId, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.decline));
    try {
      const response = await post({ path: API.DECLINE_PAYEE(payeeId) });
      callBack('Payee Declined Successfully ', 'success');
      dispatch(slice.actions.declinePayeeSuccess(response));
      dispatch(slice.actions.actionEnd(actionsNames.decline));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.decline));
    }
  };
}

export function closePayee(payeeId, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.close));
    try {
      const response = await post({ path: API.CLOSE_PAYEE(payeeId) });
      callBack('Payee Closed Successfully ', 'success');
      dispatch(slice.actions.closePayeeSuccess(response));
      dispatch(slice.actions.actionEnd(actionsNames.close));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.close));
    }
  };
}

export function freezePayee(payeeId, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.freeze));
    try {
      const response = await post({ path: API.FREEZE_PAYEE(payeeId) });
      callBack('Payee Frozen Successfully ', 'success');
      dispatch(slice.actions.freezePayeeSuccess(response));
      dispatch(slice.actions.actionEnd(actionsNames.freeze));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.freeze));
    }
  };
}

export function openPayee(payeeId, callBack) {
  return async () => {
    dispatch(slice.actions.actionStarted(actionsNames.open));

    try {
      const response = await post({ path: API.OPEN_PAYEE(payeeId) });
      callBack('Payee Re-Opened Successfully ', 'success');
      dispatch(slice.actions.openPayeeSuccess(response));
      dispatch(slice.actions.actionEnd(actionsNames.open));
    } catch (error) {
      callBack(error?.errors[0]?.message, 'error');
      dispatch(slice.actions.actionFailed(error));
      dispatch(slice.actions.actionEnd(actionsNames.open));
    }
  };
}
