import { createSlice } from '@reduxjs/toolkit';

import ApiConfig from '../../utils/apiConfig';
import { get, post } from '../../utils/server';
///
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  notes: [],
  note: null,
};

const slice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Notes
    getNotesSuccess(state, action) {
      state.isLoading = false;
      state.notes = action.payload;
    },

    createNotesSuccess(state, action) {
      state.isLoading = false;
      state.notes = [action.payload, ...state.notes];
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNotes(id, target) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const query = { target_entity: target, entity_id: id };
      const response = await get(ApiConfig.GET_NOTES(query));
      dispatch(slice.actions.getNotesSuccess(response.notes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createNote(target, entityId, title, content, attachment) {
  return async () => {
    try {
      const response = await post({
        path: ApiConfig.CREATE_NOTE,
        data: {
          target_entity: target,
          entity_id: entityId,
          title,
          content,
          attachment,
        },
      });
      dispatch(slice.actions.createNotesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
