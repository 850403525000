import { createSlice, current } from '@reduxjs/toolkit';


import JSZip from 'jszip';
import API from '../../utils/apiConfig';
import { get, upload, remove } from '../../utils/server';

//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  fileError: null,
  files: {},
  error: false,
  uploadError: false,
  file: [],
  fileName: '',
  fileExtension: '',
  fileType: '',
  /// upload states
  isUploading: false,
  uploadedFiles: [],
  uploadingError: false,
  // Downloand
  isDownloanding: false,

  // MLRO Export
  exportsFiles: null,
  prefix: ["mlro-exports"],
  inProgressDownload: {}
};
const slice = createSlice({
  name: 'file',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET Files Succes
    getFileSuccess(state, action) {
      state.isLoading = false;
      state.file = action.payload?.Body?.data;
      state.fileName = action.payload.Metadata.filename;
      state.fileExtension = action.payload?.Metadata?.filename?.split('.')?.slice(-1);
      state.fileType = action.payload?.Body?.type;
    },

    getFilesSuccess(state, action) {
      state.isLoading = false;
      state.files = Object.assign(state.files, action.payload);
    },
    getClearFilesSuccess(state) {
      state.files = {};
    },
    // ---- Upload Files ------- ////
    startUploading(state) {
      state.isUploading = true;
    },
    uploadHadError(state, action) {
      state.uploadingError = action.payload?.errors[0].message;
      state.isUploading = false;
    },
    uploadFileSucces(state, action) {
      state.uploadedFiles = [...state.uploadedFiles, action.payload];
      state.isUploading = false;
    },
    cleanUploadedFiles(state) {
      state.uploadedFiles = [];
      state.isUploading = false;
    },

    // ---- Download File --- /
    startDownloand(state) {
      state.isDownloanding = true;
    },
    downloadFilesSuccess(state, action) {
      const downloadLink = document.createElement('a');
      downloadLink.href = action.payload?.linkSource;
      downloadLink.download = action.payload?.fileName;
      downloadLink.click();
      state.isDownloanding = false;
    },



    // MLRO EXPORTS
    // 
    getExportsFilesSuccess(state, action) {
      state.isLoading = false;
      state.exportsFiles = action.payload;

    },

    addPrefixSuccess(state, action) {
      // if last index is not equal to action.payload add it otherwise do nothing
      if (state.prefix.length - 1 !== action.payload) {
        // no duplicates in prefix items
        if (!state.prefix.includes(action.payload)) {
          state.prefix = [...state.prefix, action.payload];
        }
      }
    }
    ,
    removePrefixSuccess(state) {
      state.prefix = state.prefix.slice(0, -1);
    },

    clearPrefixSuccess(state) {
      state.prefix = initialState.prefix
    },

    removeFile(state, action) {
      state.exportsFiles.files = current(state.exportsFiles.files).filter((file) => file.path !== action.payload);
      state.isLoading = false;
    },
    // downloadMultipleFiles
    downloadStart(state, action) {
      state.inProgressDownload = { ...state.inProgressDownload, ...action.payload };
    },
    downloadEnd(state, action) {
      // delete from object
      state.inProgressDownload = Object.keys(state.inProgressDownload).reduce((object, key) => {
        if (key !== action.payload) {
          object[key] = state.inProgressDownload[key]
        }
        return object
      }
        , {});

    },


  },
});

// Reducer
export default slice.reducer;

export const { cleanUploadedFiles, clearPrefixSuccess } = slice.actions;
// ----------------------------------------------------------------------

// Need endpoint
export function getFile(fileName) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.GET_FILE(fileName));
      dispatch(slice.actions.getFileSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFiles(key, array) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.getClearFilesSuccess());
    try {
      const results = await Promise.all(
        array.map(async (file) => {
          const data = await get(API.GET_FILE(file));
          return {
            data: Buffer.from(data?.Body?.data).toString('base64'),
            extension: file.split('.')?.slice(-1)[0],
          };
        })
      );

      dispatch(slice.actions.getFilesSuccess({ [key]: results }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function uploadFile(formData) {
  return async () => {
    dispatch(slice.actions.startUploading());
    try {
      const response = await upload({ path: API.UPLOAD_FILE, data: formData });
      dispatch(slice.actions.uploadFileSucces(response.data));
    } catch (error) {
      dispatch(slice.actions.uploadHadError(error));
    }
  };
}

export function downloadFile(fileName) {
  return async () => {
    dispatch(slice.actions.startDownloand());
    try {
      // const query = { path: fileName };
      const response = await get(API.GET_FILE(fileName));
      const data = Buffer.from(response?.Body?.data).toString('base64');
      // const extension = fileName.split('.')?.slice(-1)[0];
      const linkSource = `data:image;base64,${data}`;

      dispatch(slice.actions.downloadFilesSuccess({ linkSource, fileName }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



// NEW

export function getExportsFiles(query) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await get(API.EXPORTS_MMANAGER(query));
      dispatch(slice.actions.getExportsFilesSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function downloadExportsFile(path, onFailed) {
  return async (dispatch) => {
    try {
      const query = { path };
      const onDownloadProgress = (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        dispatch(slice.actions.downloadStart({ [path]: percentCompleted }));
        if (percentCompleted === 100) dispatch(slice.actions.downloadEnd(path));
      }
      const response = await get(API.DOWNLOAD_FILE(query), {}, onDownloadProgress);

      //  get link from response : { data}

      const link = response.data;

      // create a tag and click it
      let downloadLink = document.createElement('a');
      downloadLink.href = link;
      downloadLink.download = path;
      downloadLink.click();

      downloadLink = null;
    } catch (error) {
      if (typeof onFailed === 'function') onFailed(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function downloadMultipleFiles(paths, onDownloadFailed) {
  return async (dispatch) => {
    try {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < paths.length; i++) {
        const path = paths[i];
        // eslint-disable-next-line no-await-in-loop
        const response = await get(
          API.DOWNLOAD_FILE({ path }),
          {},
          (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch(slice.actions.downloadStart({ [path]: percentCompleted }));
            if (percentCompleted === 100) dispatch(slice.actions.downloadEnd(path));
          }
        );

        const link = response.data;
        const downloadLink = document.createElement('a');
        downloadLink.href = link;
        downloadLink.download = path;
        document.body.appendChild(downloadLink);

        // Trigger the click event
        setTimeout(() => {
          downloadLink.click();
          document.body.removeChild(downloadLink);


        }, 100); // delay each click by 100 milliseconds
      }


    } catch (error) {
      if (typeof onDownloadFailed === 'function') onDownloadFailed(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}




export function deleteFile(path, onSuccess) {
  dispatch(slice.actions.startLoading());

  return async () => {

    try {
      const data = { path };
      // await 3 seconds
      await remove({ path: API.DELETE_FILE(), data });
      dispatch(slice.actions.removeFile(path));
      if (typeof onSuccess === 'function') onSuccess();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}




export function addPrefix(prefix) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getExportsFilesSuccess([]));
      dispatch(slice.actions.addPrefixSuccess(prefix));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function removePrefix() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getExportsFilesSuccess([]));
      dispatch(slice.actions.removePrefixSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
